#canary{
  background-image: url("../images/portfolio/canary.jpg");
}
#elf1{
  background-image: url("../images/portfolio/elf1.jpg");
}
#elf2{
  background-image: url("../images/portfolio/elf2.jpg");
}
#elf3{
  background-image: url("../images/portfolio/elf3.jpg");
}
#pball{
  background-image: url("../images/portfolio/pokéball.jpg");
}
#scuplpt1{
  background-image: url("../images/portfolio/sculptest.jpg");
}
#crab{
  background-image: url("../images/portfolio/Caranguejo Rig.PNG");
}
#portrait{
  background-image: url("../images/cpt.png");
}
#img9{
  background-image: url("../images/portfolio/sculptest.jpg");
}
#img10{
  background-image: url("../images/portfolio/sculptest.jpg");
}
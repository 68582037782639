@media screen and (max-width: 720px) {
  #hero{
    .content{
      flex-direction: column-reverse;
      justify-content: center;
      gap: 1rem;
    }
  }
  .portrait{
    height: calc(60dvw + 0.1rem);
  }
  .hello-text-box{
    width: 100%;
    flex-direction: column;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @keyframes skillWheel {
    0%{
      transform: translateY(20%);
    }
    20%{
      transform: translateY(-80%);
    }
    40%{
      transform: translateY(-180%);
    }
    60%{
      transform: translateY(-280%);
    }
    80%{
      transform: translateY(-380%);
    }
    100%{
      transform: translateY(20%);
    }
  }
}
@media screen and (min-width: 720px) {
  .portrait{
    height: calc(30dvw + 0.1rem);
  }
  .hello-text-box{
    width: fit-content;
    flex-direction: row;
  }
  .wrapper{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @keyframes skillWheel {
    0%{
      transform: translateY(200%);
    }
    20%{
      transform: translateY(100%);
    }
    40%{
      transform: translateY(0%);
    }
    60%{
      transform: translateY(-100%);
    }
    80%{
      transform: translateY(-200%);
    }
    100%{
      transform: translateY(200%);
    }
  }
  /*
  .hello-text-box{
    position: absolute;
    top: 30%;
    transform: translate(-50%, -50%);
  }
  */
}
#hero{
  .content{
    justify-content: center;
  }
}
.wrapper{
  overflow: hidden;
  width: fill;
  height: 6dvh;
}
.hello-text-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .p{
    font-size: calc(100% + 1dvw);
  }
}
span{
  font-size: calc(100% + 1dvw);
  font-weight: 600;
  animation: skillWheel 5s infinite;
}
@media screen and (max-width: 720px) {
  .btt-menu{
    border: none;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .actual-mobile-menu{
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
    width: 10rem;
  }
  nav{
    font-size: calc(100% + 1dvw);
  }
  .pc-links{
    display: none;
  }
}
@media screen and (min-width: 720px) {
  .btt-menu{
    display: none;
  }
  nav{
    font-size: calc(70% + 1dvw);
  }
  .pc-links{
    display: block;
  }
  .mLink{
    display: none;
  }
}
nav{
  width: 100dvw;
  height: 10dvh;
  position: fixed;
  z-index: 1;
}
.nav-parts{
  width: fill;
  height: fill;
  display: flex;
  justify-content: center;
  align-items: center;
}
.links{
  text-decoration: none;
}
.btt-section{
  width: 100%;
  height: 10dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-links{
  width: fill;
  height: 90dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
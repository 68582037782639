.bg-purple{
 background-color:#4C00BA ; 
}
.bg-dark-purple{
  background-color:#240088 ; 
 }
.bg-pink{
  background-color:#B800D8 ; 
}
.bg-magenta{
  background-color:#FF002A ; 
}
.bg-orange{
  background-color:#FF6600 ; 
}
.bg-yellow{
  background-color:#FEC901 ; 
}
.bg-lime{
  background-color:#DFFF00 ; 
}
.bg-bege{
  background-color:#EADEB8 ; 
}
.bg-dark{
  background-color:#101010 ; 
}
.text-purple{
  color:#3200ba ; 
 }
.text-dark-purple{
  color:#240088 ; 
 }
.text-pink{
  color:#B800D8 ; 
}
.text-magenta{
  color:#FF002A ; 
}
.text-orange{
  color:#FF6600 ; 
}
.text-yellow{
  color:#FEC901 ; 
}
.text-lime{
  color: #DFFF00;
}
.text-bege{
  color:#EADEB8 ; 
}
.text-dark{
  color:#101010 ; 
}
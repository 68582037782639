@media screen and (max-width: 720px) {
  .contact-box{
    flex-direction: column;
    justify-content: center;
  }
}

.contact-box{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: fill;
  height: 25%;
}
footer{
  bottom: 0;
  width: 100dvw;
  height: 20dvh;
  .content{
    justify-content: center;
  }
}
.contact-text{
  font-size: calc(100% + 0.5dvw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer-text{
  font-size: calc(100% + 0.5dvw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.obs-text{
  font-size: calc(80% + 0.5dvw);
}

.flex-grid{
  display: flex;
  flex-direction: row;
}
.side1{
  width: 5rem;
}
.content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fill;
  position: relative;
}
.side2{
  width: 5rem;
}
section{
  width: 100dvw;
  height: 90dvh;
}
#hero{
  height: 100dvh;
}
#about{
  .content{
    flex-direction: column;
  }
}
#about{
  .content{
    flex-direction: column;
  }
}
#works{
  .content{
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
}
#contact{
  height: 70dvh;
  .content{
    flex-direction: column;
  }
}
.secName{
  display: flex;
  width: fill;
  align-items: center;
  height: 5rem;
}